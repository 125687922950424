import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';

import { MODAL_TYPE_DEMO_MODE, getIsTemplate } from '@wix/communities-blog-client-common';
import { getIsDemoMode } from '../../store/instance-values/instance-values-selectors';
import { getViewMode } from '../../selectors/view-mode-selectors';
import { keepFocus } from '../../services/keep-focus';
import Button from '../button';
import withTranslate from '../../hoc/with-translate';
import styles from '../button/button.scss';

class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.autoLogin) {
      setTimeout(() => this.handleClick(), 100);
    }
  }

  handleClick() {
    const { viewMode, isDemoMode, openModal, requestLogin, isTemplate } = this.props;

    if (isDemoMode) {
      openModal(MODAL_TYPE_DEMO_MODE, { viewMode, isTemplate });
    } else {
      requestLogin();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Button className={classNames(styles.button, 'login-button')} onClick={this.handleClick}>
        {t('login-button.sign-up')}
      </Button>
    );
  }
}

LoginButton.propTypes = {
  t: PropTypes.func.isRequired,
  autoLogin: PropTypes.bool,
  viewMode: PropTypes.string.isRequired,
  isDemoMode: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  viewMode: getViewMode(state),
  isDemoMode: getIsDemoMode(state),
  isTemplate: getIsTemplate(state),
  openModal: actions.openModal,
  requestLogin: keepFocus(actions.requestLoginPromisified),
});

export default connect(mapRuntimeToProps)(withTranslate(LoginButton));
