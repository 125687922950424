import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { BUTTON_SHARE_POST } from '../../bi-events/bi-buttons';
import {
  SOCIAL_SHARING_LINK,
  SHARE_BUTTON_TRANSLATION_KEYS,
  openShareDialog,
} from '@wix/communities-blog-client-common';
import ShareButton from '../share-button';
import ShareLink from '../share-link';
import getOuterUrl from '../../services/get-outer-url';
import { getSocialSharingProviders } from '../../selectors/app-settings-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import withSectionUrl from '../../hoc/with-section-url';
import styles from './share-form.scss';

export class ShareForm extends Component {
  state = {
    isLinkShareFormVisible: false,
  };

  getUrl = () => {
    const { path, sectionUrl } = this.props;
    return getOuterUrl(path, sectionUrl);
  };

  handleShare = provider => {
    const { buttonClicked, postId } = this.props;
    postId && buttonClicked({ action: BUTTON_SHARE_POST, postId, shareType: provider });
    if (provider === SOCIAL_SHARING_LINK) {
      this.setState({
        isLinkShareFormVisible: true,
      });
    } else {
      openShareDialog(provider, this.getUrl());
    }
  };

  handleCancel = () => {
    this.setState({
      isLinkShareFormVisible: false,
    });
  };

  renderShareButtons() {
    const { t, enabledProviders } = this.props;

    return (
      <div className={styles.content}>
        {map(enabledProviders, provider => (
          <span className={styles.button} key={provider}>
            <ShareButton
              type={provider}
              title={t(SHARE_BUTTON_TRANSLATION_KEYS[provider])}
              onClick={this.handleShare}
            />
          </span>
        ))}
      </div>
    );
  }

  renderLinkShareForm() {
    if (!this.state.isLinkShareFormVisible) {
      return null;
    }

    return (
      <div className={styles.content}>
        <ShareLink url={this.getUrl()} onCancel={this.handleCancel} />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { isLinkShareFormVisible } = this.state;
    const title = isLinkShareFormVisible ? t('share-form.share-link') : this.props.title;

    return (
      <div className={classNames(styles.container, 'blog-text-color', this.props.titleFontClassName)}>
        <div className={styles.message}>
          <span className={styles.title}>{title}</span>
        </div>

        {isLinkShareFormVisible ? this.renderLinkShareForm() : this.renderShareButtons()}
      </div>
    );
  }
}

ShareForm.propTypes = {
  title: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  sectionUrl: PropTypes.string,
  buttonClicked: PropTypes.func.isRequired,
  enabledProviders: PropTypes.array.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  enabledProviders: getSocialSharingProviders(state),
  buttonClicked: actions.buttonClicked,
});

export default flowRight(connect(mapRuntimeToProps), withFontClassName, withTranslate, withSectionUrl)(ShareForm);
