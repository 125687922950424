import { flowRight, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import {
  SECTIONS,
  isLayoutNameTextOnImage,
  isLayoutNameMobile,
  isLayoutNameSlider,
  isLayoutNameList,
} from '@wix/communities-blog-client-common';

import PostListItemHeader from '../post-list-item-header';
import PostListItemProGalleryTitle from './post-list-item-pro-gallery-title';
import PostListItemProGalleryExcerpt from './post-list-item-pro-gallery-excerpt';
import PostFooter from '../post-footer';
import Link from '../link/internal-link';
import UnpublishedChangesBadge from '../unpublished-changes-badge';
import { getFeedColorClassName } from '../../services/layout-config';
import { HorizontalSeparatorForPostCard } from '../separator';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../hoc/with-post-font-size';
import withPermissions from '../../hoc/with-permissions';
import { getPostCoverImageSrc } from '../../selectors/post-selectors';
import { getPostActions } from '../../services/post-actions';
import { getIsRTL } from '../../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsMoreButtonEnabled } from '../../selectors/app-settings-selectors';
import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';

import styles from '../../styles/post-list-item.scss';

export const PostListItem = ({
  type,
  post,
  query,
  onLikeClick,
  itemConfig,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  hasUnpublishedChanges,
  isMetadataFooterVisible,
  isMetadataHeaderVisible,
  showMoreButton,
  showPostDescription,
  applyFeedDesign,
  getPostClassName,
  index,
  isMoreButtonEnabled,
  layoutSidesPadding,
  hideAuthorBadge,
  isRTL,
  galleryOptions = {},
  textBoxAlignment,
  section,
}) => {
  const { lineCount, displayFooterIcons } = itemConfig;
  const postLink = `/${post.slug}`;
  const unpublishedBadge = hasUnpublishedChanges ? (
    <div className={styles.unpublishedBadge}>
      <UnpublishedChangesBadge className="blog-text-color" borderClassName={styles.unpublishedBadgeBorder} />
    </div>
  ) : null;

  const layoutList = isLayoutNameList(type);
  const isMobileLayout = isLayoutNameMobile(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withDescription = showPostDescription && !layoutList;
  const containerClassName = classNames(
    styles.container,
    styles.proGallery,
    isRTL && styles.isRTL,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    !applyFeedDesign && !isLayoutNameTextOnImage(type) && 'blog-hover-container',
    !isLayoutNameTextOnImage(type) && getPostClassName('hover-container'),
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
    isMobileLayout && index > 0 && styles.newPadding,
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
    isMobileLayout ? styles.newPadding : styles.boxShadow,
  );

  const linkClassName = classNames(
    styles.textWrapper,
    styles.proGallery,
    styles[type],
    post.isPinned && styles.withIcons,
    getPostClassName('link-hashtag-hover-color'),
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    withoutFooter && styles.withoutFooter,
    !withDescription && withoutFooter && styles.withoutBottomMargin,
    !withDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleClassName = classNames(getPostClassName('title-font', getFeedColorClassName(type, 'title-color')));
  const contentClassName = classNames(
    styles.content,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    getPostClassName('description-style-font'),
  );
  const headerClassName = classNames(contentFontClassName, getPostClassName('description-font'));
  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
  );
  const titleStyle = isMobileLayout ? { fontSize: postTitleFontSize } : {};
  const contentStyle = !applyFeedDesign || isMobileLayout ? { fontSize: postDescriptionFontSize } : {};

  const layoutSlider = isLayoutNameSlider(type);
  const sliderContainerStyle = {
    height: galleryOptions.slideshowInfoSize,
    bottom: -galleryOptions.slideshowInfoSize,
  };
  const containerStyle = {
    ...(getPostCoverImageSrc(post) && isLayoutNameTextOnImage(type) && { background: 'transparent' }),
    borderWidth: 0,
    ...(layoutSlider ? sliderContainerStyle : {}),
  };

  if (layoutList) {
    containerStyle.background = 'transparent';
    containerStyle.paddingTop = '12px';
    containerStyle.paddingBottom = '12px';
    textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
      ? (containerStyle.marginLeft = '14px')
      : (containerStyle.marginRight = '14px');
  }

  const getLayoutPaddingStyles = (layoutSidesPadding, layoutTextOnImage, isFooter = false) => {
    if (!layoutTextOnImage && isFooter) {
      return {};
    }

    return isNumber(layoutSidesPadding)
      ? {
          paddingLeft: layoutSidesPadding,
          paddingRight: layoutSidesPadding,
          ...(isFooter && {
            left: 0,
            right: 0,
          }),
        }
      : {};
  };

  const Header = (
    <PostListItemHeader
      className={headerClassName}
      hasUnpublishedChanges={hasUnpublishedChanges}
      post={post}
      showMoreButton={isMoreButtonEnabled}
      hideAuthorBadge={hideAuthorBadge}
      showProfileImage={!isLayoutNameTextOnImage(type)}
      style={{ fontSize: postMetadataFontSize }}
      type={type}
      isProGallery={true}
    />
  );

  return (
    <article className={containerClassName} style={containerStyle} data-hook="post-list-item">
      <div
        style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type))}
        className={contentWrapperClassName}
      >
        {!layoutList && isMetadataHeaderVisible && Header}
        <Link to={postLink} className={linkClassName} addHoverClasses={false}>
          {unpublishedBadge}
          <div
            style={{ fontSize: postTitleFontSize }}
            className={titleContainerClassName}
            data-hook="post-list-item__title"
          >
            <PostListItemProGalleryTitle
              type={type}
              title={post.title}
              query={query}
              style={titleStyle}
              className={titleClassName}
              lineCount={lineCount.title}
            />
          </div>
          {withDescription && lineCount.description ? (
            <div style={contentStyle} className={contentClassName} data-hook="post-description">
              <PostListItemProGalleryExcerpt post={post} query={query} lineCount={lineCount.description} />
            </div>
          ) : null}
        </Link>
        {layoutList && Header}
        {isMetadataFooterVisible && (
          <div
            style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type), true)}
            className={classNames(styles.footer, styles[type], styles.withoutCover, styles[section])}
          >
            {!isMobileLayout && !layoutList && (
              <HorizontalSeparatorForPostCard className={classNames(styles.separator, styles[type])} />
            )}
            <div style={{ fontSize: postMetadataFontSize }}>
              <PostFooter
                type={type}
                post={post}
                postLink={postLink}
                onLikeClick={onLikeClick}
                displayIcons={displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
              />
            </div>
          </div>
        )}
        {isMobileLayout && (
          <HorizontalSeparatorForPostCard
            className={classNames(
              styles.separator,
              styles.mobileSeparator,
              styles[type],
              isMetadataFooterVisible && styles.withMetadata,
            )}
          />
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  query: PropTypes.string,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  hasUnpublishedChanges: PropTypes.bool,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  index: PropTypes.number,
  isMoreButtonEnabled: PropTypes.bool,
  layoutSidesPadding: PropTypes.number,
  hideAuthorBadge: PropTypes.bool,
  hideThreeDots: PropTypes.bool,
  isRTL: PropTypes.bool,
  galleryOptions: PropTypes.object,
  textBoxAlignment: PropTypes.string,
};

PostListItem.defaultProps = {
  isMetadataFooterVisible: true,
  isMetadataHeaderVisible: true,
};

const mapRuntimeToProps = (state, { post, canSee, hideThreeDots }) => {
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const showMoreButton = Boolean(postActions.length);

  return {
    isRTL: getIsRTL(state),
    showMoreButton,
    isMoreButtonEnabled: !hideThreeDots && getIsMoreButtonEnabled(state, showMoreButton),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  connect(mapRuntimeToProps),
)(PostListItem);
