import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';
import {
  MODAL_TYPE_SHARE_LINK,
  SOCIAL_SHARING_LINK,
  SHARE_BUTTON_TRANSLATION_KEYS,
  openShareDialog,
} from '@wix/communities-blog-client-common';
import { BUTTON_SHARE_POST } from '../../bi-events/bi-buttons';
import PostShareButton from '../../../post-page/components/post-share-button';
import withTranslate from '../../hoc/with-translate';
import withSectionUrl from '../../hoc/with-section-url';
import getOuterUrl from '../../services/get-outer-url';
import { isSite } from '../../selectors/view-mode-selectors';
import styles from './post-social-actions.scss';

const containerId = 'post-social-actions';

export class PostSocialActions extends Component {
  url = () => getOuterUrl(this.props.path, this.props.sectionUrl);

  handleShare = provider => {
    const { buttonClicked, postId, isSite, openModal } = this.props;
    if (!isSite) {
      return;
    }

    postId && buttonClicked({ action: BUTTON_SHARE_POST, postId, shareType: provider });
    if (provider === SOCIAL_SHARING_LINK) {
      openModal(MODAL_TYPE_SHARE_LINK, { url: this.url() });
    } else {
      openShareDialog(provider, this.url());
    }
  };

  render() {
    const { t, isSite, enabledProviders } = this.props;
    const tooltipText = isSite ? '' : t('post-social-actions.preview-mode-tooltip');

    return (
      <div className={classNames(styles.container, 'blog-text-color')} tabIndex="-1" id={containerId}>
        {map(enabledProviders, provider => (
          <span key={provider} className={styles.button}>
            <PostShareButton
              type={provider}
              title={t(SHARE_BUTTON_TRANSLATION_KEYS[provider])}
              onClick={this.handleShare}
              tooltipText={tooltipText}
            />
          </span>
        ))}
      </div>
    );
  }
}

PostSocialActions.propTypes = {
  path: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSite: PropTypes.bool,
  sectionUrl: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isSite: isSite(state),
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate, withSectionUrl)(PostSocialActions);
