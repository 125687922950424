import React from 'react';
import PropTypes from 'prop-types';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import SimpleRenderer from '../simple-renderer';
import DotDotDot from '../dotdotdot';
import styles from './responsive-content-excerpt.scss';

const ResponsiveContentExcerpt = ({ post: { content, excerpt }, query, lineCount }) => {
  return (
    <div>
      <DotDotDot clamp="auto" useExactLineHeight={true} maxLineCount={lineCount} className={styles.container}>
        <SimpleRenderer contentState={content} excerpt={excerpt} query={query} maxLength={POST_EXCERPT_MAX_LENGTH} />
      </DotDotDot>
    </div>
  );
};

ResponsiveContentExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  lineCount: PropTypes.number,
};

export default ResponsiveContentExcerpt;
