import URI from 'urijs';
import { reduce, endsWith } from 'lodash';
import { readableUrl } from '@wix/communities-blog-client-common';

export const getCategoryUrl = (sectionUrl, path, slug, isReadable) => {
  if (sectionUrl) {
    const segments = [path, slug];
    if (endsWith(slug, '/')) {
      segments.push('');
    }

    let url = reduce(segments, (url, segment) => url.segment(segment), new URI(sectionUrl)).toString();

    if (isReadable) {
      url = readableUrl(url);
    }

    return url;
  }

  return '';
};
