import { get, intersection, map, trim, trimEnd } from 'lodash';

import { removeCorruptedEntities } from '@wix/communities-blog-client-common';
import { getCategoryIds } from '../selectors/categories-selectors';

const normalizeCategoryIds = (post, blogCategoryIds) =>
  post.categories
    ? post.categories.map(category => category._id)
    : discardDeletedCategoryIds(post.categoryIds, blogCategoryIds);

export const getOwnerTitle = post => trim(get(post, 'owner.name'));

export const discardDeletedCategoryIds = (postCategoryIds, blogCategoryIds) =>
  intersection(postCategoryIds || [], blogCategoryIds);

export const normalizePost = ({ post, blogCategoryIds }) => {
  const normalized = { ...post, categoryIds: normalizeCategoryIds(post, blogCategoryIds) };

  if (normalized.content) {
    normalized.content = removeCorruptedEntities(normalized.content);
  }

  const draft = normalized.draft;
  if (!draft) {
    return normalized;
  }

  draft.categoryIds = normalizeCategoryIds(draft, blogCategoryIds);

  if (draft.content) {
    draft.content = removeCorruptedEntities(draft.content);
  }

  if (post.firstPublishedDate && !draft.firstPublishedDate) {
    draft.firstPublishedDate = post.firstPublishedDate;
  }

  return normalized;
};

export const normalizePosts = ({ posts, blogCategoryIds, discardCategories }) =>
  map(posts, post => normalizePost({ post, blogCategoryIds, discardCategories }));

export const normalizePostEntities = (entities, state) =>
  map(entities, entity =>
    entity.type === 'post' ? normalizePost({ post: entity, blogCategoryIds: getCategoryIds(state) }) : entity,
  );

export const getCanonicalPostUrl = ({ post, postPageSectionUrl }) => {
  if (post.canonicalUrl) {
    return post.canonicalUrl;
  }

  return `${trimEnd(postPageSectionUrl, '/')}/${post.slug}`;
};

export const hasUnpublishedChanges = post => Boolean(post && post.hasUnpublishedChanges);
