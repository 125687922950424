import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';

import {
  SECTIONS,
  SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
} from '@wix/communities-blog-client-common';
import PostListItemHeader from '../../../../common/components/post-list-item-header';
import PostTitle from '../../../../common/components/post-title';
import PostListItemCover from '../../../../common/components/post-list-item-cover';
import Link from '../../../../common/components/link/internal-link';
import { getPostCover } from '../../../../common/services/get-post-cover';
import { getLineCounts, getFeedColorClassName } from '../../../../common/services/layout-config';
import { HorizontalSeparatorForPostCard } from '../../../../common/components/separator';
import withFeedBorderWidth from '../../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../../common/hoc/with-font-class-name';
import withPostFontSize from '../../../../common/hoc/with-post-font-size';
import withPermissions from '../../../../common/hoc/with-permissions';
import withAuth from '../../../../common/hoc/with-auth';
import { getSection } from '../../../../common/selectors/section-selectors';
import { getIsMemberAreaInstalled } from '../../../../common/store/communities-context/communities-context-selectors';
import { getPostActions } from '../../../../common/services/post-actions';
import LikeButtonWithCount from '../../../../common/components/like-button-with-count';
import CommentCountCompact from '../../../../comments/components/comment-count-compact';
import ViewCountCompact from '../../../../common/components/view-count-compact';
import PostHeaderIcons from '../../../../common/components/post-header-icons';
import { MoreButton } from '../../../../common/components/more-button';
import PostActions from '../../../../common/components/post-actions';

import { ThreeDotsIcon } from '../../../../common/components/icons/three-dots-icon';
import withLayoutColorClasses from '../../../../common/hoc/with-layout-color-classes';
import withIsFeedDesignEnabled from '../../../../common/hoc/with-is-feed-design-enabled';
import { getSBSLayoutImagePosition, getIsMoreButtonEnabled } from '../../../../common/selectors/app-settings-selectors';
import { getViewCount, getCommentCount } from '../../../../common/store/post-counters/post-counters-selectors';
import styles from './side-by-side.scss';

export const PostListItemSideBySide = ({
  borderWidth,
  contentFontClassName,
  forPublicUser,
  getPostClassName,
  hasUnpublishedChanges,
  iconColorClassName,
  itemConfig,
  onLikeClick,
  post,
  postMetadataFontSize,
  postTitleFontSize,
  query,
  showCommentCount,
  showLikeCount,
  showViewCount,
  sideBySideLayoutImagePosition,
  titleFontClassName,
  type,
  showMoreOptionsMenu,
  isMoreButtonEnabled,
  showAuthorName,
  showReadingTime,
  showPublishDate,
  viewCount,
  totalComments,
}) => {
  const { shouldRender: withCover } = getPostCover(post);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;

  const containerClassName = classNames(
    styles.container,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
  );

  const linkClassName = classNames(styles.textWrapper, post.isPinned && styles.withIcons);
  const headerClassName = classNames(styles.header, contentFontClassName);

  const titleStyle = { fontSize: postTitleFontSize };
  const showCommentStats = showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  const moreButtonId = `more-button-${post._id}`;

  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
    styles.footer,
  );

  const countersVisible = showLikeCount || showCommentStats || showViewCount;
  const isImagePositionNone = sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_NONE;
  const showFooter = isMoreButtonEnabled || countersVisible || !isImagePositionNone;
  const showMoreButtonInHeading = isMoreButtonEnabled && !countersVisible && isImagePositionNone;

  const showPostCover = getPostCover(post).shouldRender && !isImagePositionNone;

  const coverWrapperClassName = classNames(
    styles.imageContainer,
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT && styles.rightMargin,
  );

  const moreButtonIcon = (
    <ThreeDotsIcon className={classNames(iconColorClassName, 'blog-hover-container-element-fill')} />
  );

  const isMoreButtonInHeadingEnabled = showMoreOptionsMenu && showMoreButtonInHeading;
  const showHeader = isMoreButtonInHeadingEnabled || showAuthorName || showReadingTime || showPublishDate;
  const separatorClass = showFooter ? styles.separator : classNames(styles.separator, styles.withoutFooter);

  return (
    <article className={containerClassName} tabIndex="0" style={{ borderWidth }} data-hook="post-list-item">
      <div
        className={classNames(
          styles.flexContainer,
          sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT && styles.pullImageLeft,
        )}
      >
        <div className={styles.titleContainer}>
          <Link to={postLink} className={linkClassName} addHoverClasses={false}>
            <PostTitle
              type={type}
              title={post.title}
              lineCount={lineCounts.title}
              query={query}
              style={titleStyle}
              className={classNames(styles.title, titleFontClassName)}
            />
          </Link>
          {showHeader && (
            <PostListItemHeader
              className={headerClassName}
              fixedMetadataHeight={false}
              hasUnpublishedChanges={hasUnpublishedChanges}
              post={post}
              showHeaderIcons={false}
              showMoreButton={isMoreButtonInHeadingEnabled}
              showProfileImage={false}
              style={{ fontSize: postMetadataFontSize }}
              moreButtonIcon={moreButtonIcon}
              moreButtonClass={styles.moreButtonInHeading}
              type={type}
            />
          )}
        </div>
        {showPostCover && (
          <div className={coverWrapperClassName}>
            <PostListItemCover
              post={post}
              postLink={postLink}
              type={type}
              canPlayVideo
              videoClassName={styles.videoEmbed}
              imageClassName={styles.image}
              isMobile
            />
          </div>
        )}
      </div>
      {showFooter && (
        <div className={footerClassName}>
          {countersVisible && (
            <div className={styles.socialButtons} style={{ fontSize: postMetadataFontSize }}>
              {showLikeCount && (
                <div className={styles.socialButton}>
                  <LikeButtonWithCount
                    onClick={forPublicUser(() => onLikeClick(post._id))}
                    entity={post}
                    className="post-footer__like-button"
                    showZero
                    switchCounterAndButton
                  />
                </div>
              )}
              {showViewCount && (
                <div className={classNames('description-font', styles.socialButton)}>
                  <ViewCountCompact count={viewCount} />
                </div>
              )}
              {showCommentStats && (
                <div className={classNames('description-font', styles.socialButton)}>
                  <CommentCountCompact displayIcons={itemConfig.displayFooterIcons} count={totalComments} showZero />
                </div>
              )}
            </div>
          )}
          <PostHeaderIcons post={post} className={styles.icons} iconClassName="blog-button-fill" />
          {isMoreButtonEnabled && (
            <div className="blog-more-icon-fill">
              <MoreButton className={styles.moreButton} id={moreButtonId} icon={moreButtonIcon}>
                <PostActions post={post} focusOnCloseId={moreButtonId} />
              </MoreButton>
            </div>
          )}
        </div>
      )}
      <HorizontalSeparatorForPostCard className={separatorClass} />
    </article>
  );
};

PostListItemSideBySide.propTypes = {
  borderWidth: PropTypes.number,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  hasUnpublishedChanges: PropTypes.bool,
  iconColorClassName: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  query: PropTypes.string,
  section: PropTypes.oneOf(SECTIONS),
  showCommentCount: PropTypes.bool,
  showCommentStats: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  showViewCount: PropTypes.bool,
  sideBySideLayoutImagePosition: PropTypes.oneOf([
    SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
  ]),
  titleFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMoreOptionsMenu: PropTypes.bool,
  isMoreButtonEnabled: PropTypes.bool,
  showAuthorName: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
};

PostListItemSideBySide.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
  showLikeCount: true,
};

const mapRuntimeToProps = (state, { post, canSee }) => {
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const section = getSection(state);
  const showMoreButton = Boolean(postActions.length);

  return {
    showMoreButton,
    section,
    sideBySideLayoutImagePosition: getSBSLayoutImagePosition(state, section),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    viewCount: getViewCount(state, post._id),
    totalComments: getCommentCount(state, post._id),
  };
};

export default flowRight(
  withLayoutColorClasses,
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  withAuth,
  connect(mapRuntimeToProps),
)(PostListItemSideBySide);
