import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import clamp from './clamp';

class DotDotDot extends Component {
  componentDidMount() {
    if (this.props.clamp) {
      this.clampText(ReactDOM.findDOMNode(this.refs.container));
      window.addEventListener('resize', this.update, false);
    }
  }

  componentWillUnmount() {
    if (this.props.clamp) {
      window.removeEventListener('resize', this.update, false);
    }
  }

  componentDidUpdate() {
    if (this.props.clamp) {
      this.clampText(ReactDOM.findDOMNode(this.refs.container));
    }
  }

  update = () => this.forceUpdate();

  clampText(container) {
    if (!this.props.clamp) {
      return;
    }

    if (container.length) {
      throw new Error('Please provide exacly one child to dotdotdot');
    }

    clamp(container, this.props);
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={className} ref="container">
        {children}
      </div>
    );
  }
}

DotDotDot.propTypes = {
  children: PropTypes.node,
  clamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  truncationChar: PropTypes.string,
  useNativeClamp: PropTypes.bool,
  useExactLineHeight: PropTypes.bool,
  className: PropTypes.string,
  maxLineCount: PropTypes.number,
};

DotDotDot.defaultProps = {
  truncationChar: '\u2026',
  useNativeClamp: true,
};

export default DotDotDot;
