import React from 'react';
import PropTypes from 'prop-types';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import DotDotDot from '../dotdotdot';
import Highlighter from '../highlighter';
import { getContentText } from '../../services/content-text';
import styles from './post-list-item-pro-gallery-excerpt.scss';

const PostListItemProGalleryExcerpt = ({ post: { content, excerpt }, query, lineCount }) => {
  const text = excerpt
    ? excerpt.slice(0, POST_EXCERPT_MAX_LENGTH)
    : getContentText(content).slice(0, POST_EXCERPT_MAX_LENGTH);

  return (
    <div className={styles.container}>
      <DotDotDot clamp="auto" maxLineCount={lineCount} className={styles.text}>
        {query ? <Highlighter text={text} query={query} /> : text}
      </DotDotDot>
    </div>
  );
};

PostListItemProGalleryExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  query: PropTypes.string,
  lineCount: PropTypes.number,
};

export default PostListItemProGalleryExcerpt;
