import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import CreatePostButton from '../create-post-button';
import { POSTS_PER_PAGE } from '@wix/communities-blog-client-common';
import styles from './post-list.scss';

class PostListSimple extends Component {
  renderHeader() {
    const { category, showCreatePostAction, entityCount } = this.props;

    if (!showCreatePostAction && entityCount <= POSTS_PER_PAGE) {
      return <div className={styles.largeHeader} />;
    }

    return (
      <div className={styles.largeHeader}>
        {showCreatePostAction && <CreatePostButton categorySlug={category && category.slug} />}
      </div>
    );
  }

  renderPosts = () => {
    const { posts, type, onLikeClick, ItemComponent, itemConfig } = this.props;

    return posts.map(post => (
      <div key={post._id} className={styles.listItemContainer}>
        <div className={styles.listItem}>
          <ItemComponent type={type} post={post} onLikeClick={onLikeClick} itemConfig={itemConfig} />
        </div>
      </div>
    ));
  };

  render() {
    const { type } = this.props;
    return (
      <div className={styles[type]} data-hook="post-list">
        {this.renderHeader()}
        <div className={classNames(styles.list, styles[type])}>{this.renderPosts()}</div>
      </div>
    );
  }
}

PostListSimple.propTypes = {
  category: PropTypes.object,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  posts: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onLikeClick: PropTypes.func,
  showCreatePostAction: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  itemConfig: PropTypes.object,
};

export default PostListSimple;
