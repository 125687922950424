import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  isLayoutPGSideBySide,
  isLayoutPGOneColumn,
  isLayoutFullPost,
  isLayoutProGallery,
  getIsAppBundle,
} from '@wix/communities-blog-client-common';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import withLayoutProps from '../../hoc/with-layout-props';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getLayoutPostSize,
} from '../../selectors/app-settings-selectors';
import { getSection } from '../../selectors/section-selectors';
import styles from './desktop-header.scss';
import { connect } from '../runtime-context';

const renderMenu = menu => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({
  left,
  right,
  menu,
  className,
  createdWithResponsiveEditor,
  isAppBundle,
  layoutMargins,
  layoutType,
  layoutPostSize,
}) => {
  const containerClassName = classNames(styles.container, className, 'blog-header-background-color');
  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
  const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);
  const layoutFullPost = isLayoutFullPost(layoutType);

  const wrapperClassName = classNames(
    styles.header,
    !isLayoutProGallery(layoutType) && styles.notPGLayout,
    isAppBundle && (!isLayoutProGallery(layoutType) || createdWithResponsiveEditor) && styles.withPadding,
  );

  const containerStyles =
    createdWithResponsiveEditor || layoutPGSideBySide || layoutPGOneColumn || !layoutMargins
      ? {}
      : { paddingRight: layoutMargins, paddingLeft: layoutMargins };

  const wrapperStyles = {
    ...((layoutPGSideBySide || layoutPGOneColumn) && { maxWidth: layoutPostSize }),
    ...(createdWithResponsiveEditor && layoutFullPost && { maxWidth: 940 }),
  };

  return (
    <div style={containerStyles} className={containerClassName} data-hook="blog-desktop-header-container">
      <div style={wrapperStyles} className={wrapperClassName}>
        {left}
        {!createdWithResponsiveEditor && right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  className: PropTypes.string,
  createdWithResponsiveEditor: PropTypes.bool.isRequired,
  isAppBundle: PropTypes.bool.isRequired,
  layoutMargins: PropTypes.number,
  layoutType: PropTypes.number.isRequired,
  layoutPostSize: PropTypes.number,
};

const mapRuntimeToProps = (state, { rootWidth, layoutType, layoutName }, actions, host) => {
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  const layoutMargins = getLayoutMargins({ state });

  return {
    createdWithResponsiveEditor,
    isAppBundle: getIsAppBundle(state),
    layoutMargins,
    layoutType,
    layoutPostSize: getLayoutPostSize({
      state,
      section: getSection(state),
      layoutType,
      layoutName,
      hostWidth: host.dimensions.width,
      rootWidth,
      layoutMargins: createdWithResponsiveEditor ? null : layoutMargins,
    }),
  };
};

export default flowRight(withResponsiveContext, withLayoutProps(), connect(mapRuntimeToProps))(DesktopHeader);
