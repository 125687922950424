import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';

import withTranslate from '../../../common/hoc/with-translate';
import styles from './update-urls-notification.scss';
import { getIsRedirectsNotSet } from '@wix/communities-blog-client-common';

const UpdateUrlsNotification = ({ t, isRedirectsNotSet }) => {
  return isRedirectsNotSet ? (
    <div className={styles.container}>{t('content-migration.update-urls-notification')}</div>
  ) : null;
};

UpdateUrlsNotification.propTypes = {
  isRedirectsNotSet: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = state => ({
  isRedirectsNotSet: getIsRedirectsNotSet(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(UpdateUrlsNotification);
