import { createAction } from '@wix/communities-blog-client-common';
import { addModalToRegistry, resolveModalInRegistry } from './opened-modals-promise-registry';

export const OPEN_MODAL = 'modal/OPEN';
export const CLOSE_MODAL = 'modal/CLOSE';

export const openModal = createAction(
  OPEN_MODAL,
  (type, props, meta, correlationId) => ({ type, props, correlationId }),
  (type, props, meta) => meta,
);
export const closeModal = createAction(CLOSE_MODAL, payload => payload);

// wrapper for component side
export const withPromisifiedOpenModal = openModal => (type, props, meta) => {
  const { correlationId, promise } = addModalToRegistry();
  openModal(type, props, meta, correlationId);
  return promise;
};

// wrapper for component side
export const withPromisifiedCloseModal = closeModal => props => {
  resolveModalInRegistry(props.correlationId, props.resolve);
  return closeModal(props);
};
