import { chain, curry, includes, filter, find, size, get, trimEnd, trimStart } from 'lodash';
import { createSelector } from 'reselect';
import { getCanonicalPostUrl } from '../services/post-utils';

import {
  sortFeedPosts,
  sortCategoryPosts,
  sortProfilePosts,
  sortDrafts,
} from '@wix/communities-blog-universal/dist/src/utils/sort-posts';
import { getPostPageSectionUrl, ENTITY_TYPE_POSTS } from '@wix/communities-blog-client-common';

import { getEntitiesByPage, getCurrentPageEntities } from './pagination-selectors';

import { secondParam } from '../services/param-selectors';
import { isValidAdvancedSlug } from '../services/slug';

export const getPostMap = state => state.posts;

export const getPostCount = createSelector(getPostMap, posts => size(posts));

export const getPost = createSelector([getPostMap, secondParam], (posts, postId) => posts[postId]);

export const getPostBySlug = createSelector([getPostMap, secondParam], (posts, slug) => {
  if (typeof slug !== 'string') {
    return undefined;
  }

  const lowerCaseSlug = slug.toLowerCase();
  const [year, month, postSlug] = trimStart(lowerCaseSlug, '/').split('/');
  if (isValidAdvancedSlug({ year, month, postSlug })) {
    return find(posts, post => includes(post.slugs, lowerCaseSlug) || includes(post.slugs, `${lowerCaseSlug}/`));
  }

  return find(posts, post => includes(post.slugs, lowerCaseSlug));
});

export const getPostByIdOrSlug = (state, idOrSlug) => getPost(state, idOrSlug) || getPostBySlug(state, idOrSlug);

export const getPostIdBySlug = (state, slug) => get(getPostBySlug(state, slug), '_id');

export const getPostSlugById = (state, id) => get(getPost(state, id), 'slug');

const getEntityIdsByPage = (state, page) => get(getEntitiesByPage(state, ENTITY_TYPE_POSTS), page, []);

const getEntityIdsByIdAndPage = (state, id, page) => getEntityIdsByPage(state, page);

const getOwnerId = (state, ownerId) => ownerId;

const getSiteMemberId = (state, siteMemberId) => siteMemberId;

const isPublished = post => post && post.status === 'published';

export const isScheduled = post => post && post.status === 'scheduled';

export const getPostScheduleDate = post => post && post.scheduledPublishDate;

const isUnpublished = post => post && post.status === 'unpublished';

const hasCategoryId = curry((categoryId, post) => post && includes(post.categoryIds, categoryId));

const hasTagId = curry((tagId, post) => post && includes(post.tagIds, tagId));

export const getSortedPostsByCategoryId = createSelector([getPostMap, secondParam], (posts, categoryId) =>
  sortCategoryPosts(
    chain(posts)
      .filter(hasCategoryId(categoryId))
      .filter(isPublished)
      .value(),
  ),
);

export const getPostsByCategoryIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, categoryId, entityIds) =>
    chain(entityIds)
      .map(id => posts[id])
      .filter(hasCategoryId(categoryId))
      .value(),
);

export const getSortedPostsByTagId = createSelector([getPostMap, secondParam], (posts, tagId) =>
  sortCategoryPosts(
    chain(posts)
      .filter(hasTagId(tagId))
      .filter(isPublished)
      .value(),
  ),
);

export const getPostsByTagIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, tagId, entityIds) =>
    chain(entityIds)
      .map(id => posts[id])
      .filter(hasTagId(tagId))
      .value(),
);

export const getFeedPosts = createSelector([getPostMap], posts =>
  sortFeedPosts(
    chain(posts)
      .filter(isPublished)
      .value(),
  ),
);

export const getFeedPostsByPage = createSelector([getPostMap, getEntityIdsByPage], (posts, entityIds) =>
  chain(entityIds)
    .map(id => posts[id])
    .filter(isPublished)
    .value(),
);

const hasOwnerId = curry((ownerId, post) => post.ownerId === ownerId);
const hasSiteMemberId = curry((siteMemberId, post) => get(post, 'owner.siteMemberId') === siteMemberId);

export const getSortedPostsByOwnerId = createSelector([getPostMap, getOwnerId], (posts, ownerId) =>
  sortProfilePosts(
    chain(posts)
      .filter(hasOwnerId(ownerId))
      .filter(isPublished)
      .value(),
  ),
);

export const getSortedPostsBySiteMemberId = createSelector([getPostMap, getSiteMemberId], (posts, siteMemberId) =>
  sortProfilePosts(
    chain(posts)
      .filter(hasSiteMemberId(siteMemberId))
      .filter(isPublished)
      .value(),
  ),
);

export const getSortedDraftsByOwnerId = createSelector([getPostMap, getOwnerId], (posts, ownerId) =>
  sortDrafts(
    chain(posts)
      .filter(hasOwnerId(ownerId))
      .filter(isUnpublished)
      .value(),
  ),
);

export const getSortedDraftsBySiteMemberId = createSelector([getPostMap, getSiteMemberId], (posts, siteMemberId) =>
  sortDrafts(
    chain(posts)
      .filter(hasSiteMemberId(siteMemberId))
      .filter(isUnpublished)
      .value(),
  ),
);

export const getPostsCountByOwnerId = createSelector(
  [getPostMap, getOwnerId],
  (posts, ownerId) => filter(posts, hasOwnerId(ownerId)).length,
);

export const getDraftsCountByOwnerId = createSelector(
  [getPostMap, getOwnerId],
  (posts, ownerId) =>
    chain(posts)
      .filter(hasOwnerId(ownerId))
      .filter(isUnpublished)
      .values().length,
);

export const getPostsByOwnerIdAndPage = createSelector(
  [getPostMap, getOwnerId, getEntityIdsByIdAndPage],
  (posts, ownerId, entityIds) =>
    chain(entityIds)
      .map(id => posts[id])
      .filter(hasOwnerId(ownerId))
      .value(),
);

export const getPublishedPosts = createSelector([getPostMap], posts => filter(posts, isPublished));
export const getDrafts = createSelector([getPostMap], posts => filter(posts, isUnpublished));

export const getPublishedPostCount = createSelector([getPostMap], posts =>
  chain(posts)
    .filter(isPublished)
    .size()
    .value(),
);

export const getDraftCount = createSelector([getPostMap], posts =>
  chain(posts)
    .filter(isUnpublished)
    .size()
    .value(),
);

export const getPostsForPage = createSelector([getPostMap, getCurrentPageEntities], (posts, entityIds) =>
  chain(entityIds)
    .map(id => get(posts, id))
    .compact()
    .value(),
);

export const getPostCanonicalUrl = (state, post) => {
  const postPageSectionUrl = getPostPageSectionUrl(state);
  return getCanonicalPostUrl({ post, postPageSectionUrl });
};

export const removeTrailingSlash = text => trimEnd(text, '/');

export const getPostAmpUrl = (state, postId, ampBaseUrl) => {
  if (!ampBaseUrl || !postId) return;

  const slug = getPostSlugById(state, postId);
  return `${removeTrailingSlash(ampBaseUrl)}/${removeTrailingSlash(slug)}`;
};

export const getLastPublishedDate = post => post.lastPublishedDate;

export const getPostCoverImage = post => post.coverImage;

export const getPostCoverImageSrc = post => get(getPostCoverImage(post), 'src');
