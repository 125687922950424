import PropTypes from 'prop-types';
import React from 'react';

import { COVER_TYPE_COLOR, COVER_TYPE_IMAGE } from '@wix/communities-blog-client-common';
import Cover from '../cover';
import styles from './page-cover.scss';

const getOverlayStyle = (coverType, backgroundColor = {}, overlayColor = {}) => {
  switch (coverType) {
    case COVER_TYPE_COLOR:
      return {
        backgroundColor: backgroundColor.color,
        opacity: backgroundColor.opacity,
      };

    case COVER_TYPE_IMAGE:
      return {
        backgroundColor: overlayColor.color,
        opacity: overlayColor.opacity,
      };

    default:
      return {};
  }
};

const PageCover = ({ coverType, cover, overlayColor, backgroundColor, width, height, children, hasActiveState }) => (
  <Cover
    cover={cover}
    showCover={coverType === COVER_TYPE_IMAGE}
    width={width}
    height={height}
    hasActiveState={hasActiveState}
  >
    <div
      className={styles.overlay}
      style={getOverlayStyle(coverType, backgroundColor, overlayColor)}
      data-hook="page-cover"
    />
    <div className={styles.content}>{children}</div>
  </Cover>
);

PageCover.propTypes = {
  coverType: PropTypes.string,
  cover: PropTypes.object,
  overlayColor: PropTypes.object,
  backgroundColor: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
  hasActiveState: PropTypes.bool,
};

export default PageCover;
