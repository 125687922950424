import { connect } from '../components/runtime-context';
import { getPostPageSectionUrl } from '@wix/communities-blog-client-common';

const withSectionUrl = WrappedComponent => {
  const mapRuntimeToProps = state => ({
    sectionUrl: getPostPageSectionUrl(state),
  });

  return connect(mapRuntimeToProps)(WrappedComponent);
};

export default withSectionUrl;
