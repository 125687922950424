import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from '../../common/components/runtime-context';

import { SECTION_CATEGORY, SECTION_HOMEPAGE } from '@wix/communities-blog-client-common';
import getDisplayName from '../../common/services/get-display-name';
import { getHeaderTextAlignment } from '../../common/selectors/app-settings-selectors';

export default function withCategoryTextAlignment(WrappedComponent) {
  const Wrapper = class extends Component {
    static displayName = `WithCategoryTextAlignment(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      categoryTextAlignment: PropTypes.number,
      homePageTextAlignment: PropTypes.number,
    };

    render() {
      const { categoryTextAlignment, ...otherProps } = this.props;
      return <WrappedComponent categoryTextAlignment={categoryTextAlignment} {...otherProps} />;
    }
  };

  hoistNonReactStatics(Wrapper, WrappedComponent);

  const mapRuntimeToProps = (state, { categoryTextAlignment }) => ({
    categoryTextAlignment: categoryTextAlignment || getHeaderTextAlignment(state, SECTION_CATEGORY),
    homePageTextAlignment: getHeaderTextAlignment(state, SECTION_HOMEPAGE),
  });

  return connect(mapRuntimeToProps)(Wrapper);
}
