import { compact, includes, flowRight, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import {
  MODAL_TYPE_SHARE_COMMENT,
  MODAL_TYPE_REPORT_COMMENT,
  MODAL_TYPE_DELETE_COMMENT,
} from '@wix/communities-blog-client-common';
import { BUTTON_ACTION_CLICK } from '../../../common/bi-events/bi-buttons';
import {
  COMMENT_ACTIONS,
  COMMENT_ACTION_SHARE,
  COMMENT_ACTION_REPORT,
  COMMENT_ACTION_EDIT,
  COMMENT_ACTION_DELETE,
} from '../../constants/comment-actions';
import ActionButton from '../../../post-page/components/action-button';
import { DeleteIcon } from '../../../common/components/icons/delete-icon';
import { ShareIcon } from '../../../common/components/icons/share-icon';
import { ReportIcon } from '../../../common/components/icons/report-icon';
import { EditIcon } from '../../../common/components/icons/edit-icon';
import { getPost } from '../../../common/selectors/post-selectors';
import { getCommentActions } from '../../services/comment-actions';
import { isSite } from '../../../common/selectors/view-mode-selectors';
import withTranslate from '../../../common/hoc/with-translate';
import withPermissions from '../../../common/hoc/with-permissions';
import withDeviceType from '../../../common/hoc/with-device-type';
import withAuth from '../../../common/hoc/with-auth';

export class CommentActions extends Component {
  openModal = type => {
    return () => {
      this.props.buttonClicked({ action: type });
      return this.props.openModal(type, {
        postId: this.props.post._id,
        postSlug: this.props.post.slug,
        commentId: this.props.comment._id,
      });
    };
  };

  handleEdit = () => {
    const { isMobile, navigateWithinPostPage, startEditingComment, comment, post } = this.props;

    if (isMobile) {
      navigateWithinPostPage(`/${post.slug}/edit-comment/${comment._id}`);
    } else {
      startEditingComment({ post, comment });
    }
  };

  componentDidMount() {
    this.props.actionsOpened({ type: 'comment' });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: 'comment' });
  }

  getButtons = () => {
    const { can, comment, t, commentActions, forPublicUser, isSite } = this.props;

    return compact([
      includes(commentActions, COMMENT_ACTION_SHARE) && (
        <ActionButton
          key={COMMENT_ACTION_SHARE}
          data-hook="comment-actions__share"
          onClick={isSite ? this.openModal(MODAL_TYPE_SHARE_COMMENT) : noop}
        >
          <ShareIcon className="blog-icon-fill" />
          {t('comment-actions.share-comment')}
        </ActionButton>
      ),
      includes(commentActions, COMMENT_ACTION_REPORT) && (
        <ActionButton
          key={COMMENT_ACTION_REPORT}
          data-hook="comment-actions__report"
          onClick={
            can(COMMENT_ACTION_REPORT, 'comment', comment)
              ? this.openModal(MODAL_TYPE_REPORT_COMMENT)
              : forPublicUser(this.openModal(MODAL_TYPE_REPORT_COMMENT))
          }
        >
          <ReportIcon className="blog-icon-fill" />
          {t('comment-actions.report-comment')}
        </ActionButton>
      ),
      includes(commentActions, COMMENT_ACTION_EDIT) && (
        <ActionButton
          key={COMMENT_ACTION_EDIT}
          data-hook="comment-actions__edit"
          onClick={can(COMMENT_ACTION_EDIT, 'comment', comment) ? this.handleEdit : forPublicUser(this.handleEdit)}
          className="comment-actions__edit"
        >
          <EditIcon className="blog-icon-fill" />
          {t('comment-actions.edit-comment')}
        </ActionButton>
      ),
      includes(commentActions, COMMENT_ACTION_DELETE) && (
        <ActionButton
          key={COMMENT_ACTION_DELETE}
          data-hook="comment-actions__delete"
          onClick={this.openModal(MODAL_TYPE_DELETE_COMMENT)}
        >
          <DeleteIcon className="blog-icon-fill" />
          {t('comment-actions.delete-comment')}
        </ActionButton>
      ),
    ]);
  };

  render() {
    return <div>{this.getButtons()}</div>;
  }
}

CommentActions.propTypes = {
  comment: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  isOwner: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  startEditingComment: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  actionsOpened: PropTypes.func.isRequired,
  actionsClosed: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  navigateWithinPostPage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSite: PropTypes.bool,
  commentActions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapRuntimeToProps = (state, { comment, canSee, visibleActions, isDesktop }, actions) => ({
  post: getPost(state, comment.postId) || comment.post,
  commentActions: getCommentActions({
    comment,
    canSee,
    visibleActions: visibleActions || COMMENT_ACTIONS,
    enableShare: true,
    enableEdit: isDesktop,
  }),
  isSite: isSite(state),
  openModal: actions.openModal,
  actionsOpened: actions.actionsOpened,
  actionsClosed: actions.actionsClosed,
  buttonClicked: data => actions.buttonClicked({ name: BUTTON_ACTION_CLICK, type: 'comment', ...data }),
  startEditingComment: actions.startEditingComment,
  navigateWithinPostPage: actions.navigateWithinPostPage,
});

export default flowRight(
  withDeviceType,
  withPermissions,
  connect(mapRuntimeToProps),
  withTranslate,
  withAuth,
)(CommentActions);
